import React from "react";
import { Card } from "../../elements/Card";
import { styles } from "./ReportCard.style";
import { ReportCardRisk } from "./components/ReportCardRisk";
import ReportCardRow from "./components/ReportCardRow";

export interface ReportCardProps {
  makeAndModelTitle: string;
  vinregTitle: string;
  purchasedTitle: string;
  statusTitle: string;
  makeAndModel: string;
  licensePlateOrVin: string;
  buttonText: string;
  hasRisk: boolean;
  riskText: string;
  details: ReportCardDetailsProps;
  displayShortCard?: boolean;
}

export interface ReportCardDetailsProps {
  purchaseDate: string;
  expiryDate: string;
  expirationReason: string;
  expired: boolean;
  testId: string;
}

export const ReportCard: React.FC<ReportCardProps> = ({
  makeAndModelTitle,
  vinregTitle,
  purchasedTitle,
  statusTitle,
  makeAndModel,
  licensePlateOrVin,
  details,
  buttonText,
  hasRisk,
  riskText,
  displayShortCard,
}) => {
  const reportCardFields = [
    { title: vinregTitle, value: licensePlateOrVin },
    { title: statusTitle, value: details.expirationReason },
    { title: purchasedTitle, value: details.purchaseDate, style: styles.purchasedField },
  ];

  return (
    <div data-testid={details.testId}>
      <Card css={styles.card()}>
        <div css={styles.risk()}>
          {displayShortCard ? (
            <>
              {reportCardFields.map(({ title, value, style }) => (
                <ReportCardRow key={title} style={style} title={title} value={value} />
              ))}
            </>
          ) : (
            <div css={styles.title()}>{makeAndModelTitle}</div>
          )}
          <div css={styles.riskInfo(displayShortCard)}>{hasRisk && <ReportCardRisk text={riskText} />}</div>
        </div>
        {!displayShortCard && (
          <>
            <div css={styles.contentValue()}>{makeAndModel}</div>
            {reportCardFields.map(({ title, value }) => (
              <ReportCardRow title={title} value={value} key={title} />
            ))}
          </>
        )}
        <div css={styles.footer()}>
          <div css={styles.viewReport(details.expired)}>
            <div css={styles.img()}>
              {details.expired ? (
                <img
                  src={require("./images/reactivate-icon-link-blue-24px.svg")}
                  alt="blue-arrow-icon"
                  title="blue-arrow-icon"
                />
              ) : (
                <img src={require("./images/view-report-icon-01.svg")} alt="report-icon" title="report-icon" />
              )}
            </div>
            {buttonText}
          </div>
        </div>
      </Card>
    </div>
  );
};
