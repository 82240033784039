import React from "react";
import { styles } from "./HighlightLabel.style";

export interface HighlightLabelProps {
  text: string;
  pricingCard?: boolean;
}

export function HighlightLabel({ text, pricingCard }: HighlightLabelProps) {
  return (
    <div css={styles.mostPopularWrapper(pricingCard)}>
      <div css={styles.mostPopular(pricingCard)}>
        <div css={styles.mostPopularLabel(pricingCard)}>{text}</div>
      </div>
    </div>
  );
}
