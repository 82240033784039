import { css } from "@emotion/react";
import { colors, spacing, mediaQuery, fontSizes, lineHeights, letterSpacings } from "../../core";

export const styles = {
  card: () =>
    css({
      position: "relative",
      fontWeight: 400,
      color: colors.headlineGrey.hex,
      transition: "border 300ms ease-in-out",
      minHeight: "106px",
      ":hover": {
        border: `1px solid ${colors.linkBlue.hex}`,
        cursor: "pointer",
      },
    }),
  title: () =>
    css({
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      color: colors.stdTextGrey.hex,
    }),
  footer: () =>
    css({
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    }),
  contentValue: () =>
    css({
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      paddingBottom: spacing.baseUnitMobile1,
      minWidth: "204px",
    }),
  viewReport: (expired) =>
    css({
      fontWeight: "bold",
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      color: colors.linkBlue.hex,
      display: "flex",
      alignItems: "center",
      marginBottom: expired ? "0px" : "6px",
    }),
  img: () =>
    css({
      marginRight: spacing.baseUnitMobile1,
      display: "flex",
      alignItems: "center",
    }),
  risk: () =>
    css({
      display: "flex",
      flexDirection: "column",
      [mediaQuery.largerThanMobile]: {
        flexDirection: "row",
      },
    }),
  riskInfo: (displayShortCard) =>
    css({
      position: "absolute",
      right: spacing.baseUnitMobile2,
      order: displayShortCard ? -1 : 0,
      [mediaQuery.largerThanMobile]: {
        order: 0,
      },
    }),
  purchasedField: () =>
    css({
      order: -2,
      [mediaQuery.largerThanMobile]: {
        display: "none",
      },
    }),
};
