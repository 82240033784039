import { spacing } from "@carfax/web-ui/core";
import { css } from "@emotion/react";

export const styles = {
  recaptcha: () =>
    css({
      display: "none",
      "&[data-captcha-show]": {
        display: "block",
        marginTop: spacing.baseUnitMobile2,
      },
    }),
};
