import React from "react";
import { styles } from "../../ReportCard.style";

export interface ReportCardRowProps {
  title: string;
  value: string;
  style?: () => {};
}

const ReportCardRow: React.FC<ReportCardRowProps> = ({ title, value, style }) => {
  return (
    <div css={style}>
      <div css={styles.title()}>{title}</div>
      <div css={styles.contentValue()}>{value}</div>
    </div>
  );
};

export default ReportCardRow;
