import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, spacing } from "../../core/styles";

export const styles = {
  root: () =>
    css({
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: spacing.baseUnitMobile3,
    }),
  title: () =>
    css({
      fontWeight: 700,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      display: "flex",
      justifyContent: "space-between",
    }),
  collapseIcon: (isCollapsed) =>
    css({
      transform: isCollapsed ? "rotate(180deg)" : "",
    }),
  collapseIconContainer: () =>
    css({
      width: `${spacing.baseUnitMobile3}`,
      cursor: "pointer",
      textAlign: "center",
    }),
  voucherInputWrapper: (isCollapsed: boolean) =>
    css({
      display: isCollapsed ? "none" : "initial",
    }),
};
