import { css, keyframes } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, mediaQuery, spacing } from "../../../../core/styles";
import { Icons } from "../../../../elements/Icon";

const moveArrow = keyframes`
    0% { transform: translateX(0px); }
    100% { transform: translateX(-576px); }
`;
const moveArrowBack = keyframes`
    0% { transform: translateX(-576px); }
    100% { transform: translateX(0px); }
    `;

export const icon = (animationBounceArrow) =>
  css({
    content: `url(${Icons.animatedArrowAfterClick})`,
    height: spacing.baseUnitMobile3,
    cursor: "pointer",
    animation: animationBounceArrow ? `${moveArrow} 300ms steps(12)` : `${moveArrowBack} 300ms steps(12)`,
    animationFillMode: "forwards",
  });

export const styles = {
  form: () =>
    css({
      position: "relative",
    }),
  searchIconWrapper: () =>
    css({
      width: "48px",
      height: "24px",
      overflow: "hidden",
      position: "relative",
      backgroundColor: "transparent",
      border: "none",
      outline: "none",
      right: 0,
      padding: 0,
      path: {
        fill: colors.linkBlue.hex,
      },
    }),
  input: (hasError) =>
    css({
      textTransform: "uppercase",
      border: `1px solid`,
      borderColor: hasError ? colors.red.hex : colors.lightGrey.hex,
      borderRadius: spacing.baseUnitMobile1,
      backgroundColor: colors.white.hex,
      padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile2}`,
      fontSize: fontSizes.body.horse,
      lineHeight: lineHeights.body.horse,
      letterSpacing: letterSpacings.body.horse,
      color: colors.headlineGrey.hex,
      width: "100%",
      height: `${spacing.baseUnitMobile7}`,
      margin: "0 auto",
      transition: "all .3s ease",

      "::placeholder": {
        color: colors.stdTextGrey.hex,
        textTransform: "none",
        fontSize: fontSizes.body.mouse,
        lineHeight: lineHeights.body.mouse,
        letterSpacing: letterSpacings.body.mouse,
        [mediaQuery.tabletOrLarger]: {
          fontSize: fontSizes.body.horse,
          lineHeight: lineHeights.body.horse,
        },
      },
      "&:focus": {
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
        borderColor: colors.linkBlue.hex,
        outline: 0,
      },
    }),
  icon: icon,
  error: () =>
    css({
      padding: `${spacing.baseUnitMobile1} ${spacing.baseUnitMobile2}`,
      color: colors.red.hex,
      fontSize: fontSizes.body.mouse,
      lineHeight: lineHeights.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
    }),
};
