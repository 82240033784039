import React from "react";
import { styles } from "./ReportCardRisk.style";

export interface ReportCardRiskProps {
  text: string;
}

export const ReportCardRisk: React.FC<ReportCardRiskProps> = ({ text }) => {
  return <div css={styles.risk()}>{text}</div>;
};
