import { css } from "@emotion/react";
import { colors, fontSizes, lineHeights, spacing } from "../../../../core";

export const styles = {
  risk: () =>
    css({
      color: colors.red.hex,
      fontSize: fontSizes.body.mouse,
      fontWeight: "bold",
      lineHeight: lineHeights.body.mouse,
      position: "relative",
      right: `-${spacing.baseUnitMobile1}`,
      paddingRight: spacing.baseUnitMobile2,

      "&::after": {
        content: `""`,
        display: "inline-block",
        width: spacing.baseUnitMobile2,
        height: spacing.baseUnitMobile2,
        backgroundColor: colors.red.hex,
        transform: "scaleX(-1)",
        borderRadius: "0 4px 4px 0",
        position: "absolute",
        right: `-${spacing.baseUnitMobile1}`,
      },
    }),
};
