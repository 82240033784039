import React, { useState } from "react";
import { styles } from "./VoucherEntryField.style";
import { Icon, Icons } from "../../elements/Icon";
import { VoucherInput, VoucherInputProps } from "./components/VoucherInput";

export interface VoucherEntryFieldProps {
  cardTitle: string;
  initiallyCollapsed?: boolean;
  collapseButtonTestId?: string;
  inputProps: VoucherInputProps;
}

export const VoucherEntryField: React.FC<VoucherEntryFieldProps> = ({
  cardTitle,
  initiallyCollapsed = true,
  collapseButtonTestId,
  inputProps,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(initiallyCollapsed);

  const onArrowClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div css={styles.root()}>
      <div css={styles.title()}>
        {cardTitle}
        <span css={styles.collapseIconContainer()} data-testid={collapseButtonTestId} onClick={onArrowClick}>
          <Icon icon={Icons.arrowUp} css={styles.collapseIcon(isCollapsed)} />
        </span>
      </div>
      <div css={styles.voucherInputWrapper(isCollapsed)}>
        <VoucherInput {...inputProps} />
      </div>
    </div>
  );
};
