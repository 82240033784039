import React, { useEffect, useState, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const NoSSR: React.FC<Props> = (props) => {
  const { children } = props;
  const [hasWindow, setHasWindow] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setHasWindow(true);
    }
  }, []);

  return <>{hasWindow && <div>{children}</div>}</>;
};

export default NoSSR;
