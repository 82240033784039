import { css } from "@emotion/react";
import { mediaQuery, colors, fontSizes, lineHeights } from "../../core/styles";

export const styles = {
  mostPopularWrapper: (pricingCard?: boolean) =>
    css({
      overflow: "hidden",
      width: pricingCard ? "80px" : "105px",
      height: pricingCard ? "80px" : "105px",
      borderTopRightRadius: "6px",
      position: "absolute",
      top: pricingCard ? "2px" : "4px",
      right: pricingCard ? "2px" : "4px",
    }),
  mostPopular: (pricingCard?: boolean) =>
    css({
      height: "0px",
      width: "0px",
      transform: "rotate(45deg)",
      color: pricingCard ? colors.white.hex : colors.green.hex,
      fontSize: fontSizes.body.mouse,
      fontWeight: "bold",
      letterSpacing: pricingCard ? "0" : "1px",
      lineHeight: lineHeights.body.mouse,
      textAlign: "center",
      position: "absolute",
      top: pricingCard ? "-10px" : "-8px",
      right: pricingCard ? "-42px" : "-33px",
      background: "transparent",
      borderLeft: pricingCard ? "60px solid transparent" : "50px solid transparent",
      borderRight: pricingCard ? "60px solid transparent" : "50px solid transparent",
      borderBottom: pricingCard ? "60px solid " + colors.green.hex : "50px solid " + colors.white.hex,
      zIndex: 20,
      [mediaQuery.tabletOrLarger]: {
        fontSize: pricingCard ? fontSizes.body.mouse : fontSizes.body.horse,
        letterSpacing: pricingCard ? "0" : "1.33px",
        fontWeight: "bold",
        top: "-10px",
        right: "-42px",
        background: "transparent",
        borderLeft: pricingCard ? "60px solid transparent" : "70px solid transparent",
        borderRight: pricingCard ? "60px solid transparent" : "70px solid transparent",
        borderBottom: pricingCard ? "60px solid transparent " + colors.green.hex : "70px solid " + colors.white.hex,
      },
    }),
  mostPopularLabel: (pricingCard?: boolean) =>
    css({
      position: "absolute",
      transform: "translateX(calc(-50% - 1px))",
      bottom: "-61px",
      marginBottom: pricingCard ? "4px" : "0px",
    }),
};
